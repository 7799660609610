<template>
  <q-layout view="lHh Lpr lFf">
    <q-header elevated class="glossy">
      <q-toolbar>
        <q-btn
          flat
          dense
          round
          @click="leftDrawerOpen = !leftDrawerOpen"
          aria-label="Menu"
          icon="fas fa-bars"
        />

        <q-toolbar-title>
          KOST - Hogast Produktstammdaten
        </q-toolbar-title>

        <q-btn
          v-if="isAdmin"
          label="Admin"
          class="q-mr-md"
          href="https://api.hogast.mksw.at/admin"
          target="_blank"
          dense
          flat
        ></q-btn>

        <div>(c) 2022 gkSW</div>
      </q-toolbar>
    </q-header>

    <q-drawer
      v-model="leftDrawerOpen"
      show-if-above
      bordered
      class="bg-grey-2"
    >
      <q-list>

        <q-item class="flex column justify-center items-center">
          <q-img src="../assets/img-removebg-preview.png"></q-img>
          <q-item-label header>Hogast Produkte</q-item-label>
        </q-item>

        <q-item :to="{name: 'dashboard'}" exact>
          <q-item-section avatar>
            <q-icon name="fas fa-home"/>
          </q-item-section>
          <q-item-section>
            <q-item-label>Dashboard</q-item-label>
          </q-item-section>
        </q-item>

        <q-item :to="{name: 'customers'}" exact v-if="countCustomers !== 1">
          <q-item-section avatar>
            <q-icon name="fas fa-user-friends"/>
          </q-item-section>
          <q-item-section>
            <q-item-label>Kundenliste</q-item-label>
          </q-item-section>
        </q-item>

        <q-item :to="{name: 'vendor-list', params: {customer_slug: customerSlug}}" exact v-if="countCustomers === 1">
          <q-item-section avatar>
            <q-icon name="fas fa-people-carry-box"/>
          </q-item-section>
          <q-item-section>
            <q-item-label>Lieferantenliste</q-item-label>
          </q-item-section>
        </q-item>

        <q-item to="/products/search" exact>
          <q-item-section avatar>
            <q-icon name="fas fa-magnifying-glass"/>
          </q-item-section>
          <q-item-section>
            <q-item-label>Produktsuche</q-item-label>
          </q-item-section>
        </q-item>

        <!--
        <q-item to="/about" exact>
          <q-item-section avatar>
            <q-icon name="fas fa-info"/>
          </q-item-section>
          <q-item-section>
            <q-item-label>About</q-item-label>
          </q-item-section>
        </q-item>
        -->

        <q-item to="/login" exact v-if="!token">
          <q-item-section avatar>
            <q-icon name="fas fa-home"/>
          </q-item-section>
          <q-item-section>
            <q-item-label>Einloggen({{ token }})</q-item-label>
          </q-item-section>
        </q-item>

        <q-item @click="logout()" to="/login" exact v-if="token">
          <q-item-section avatar>
            <q-icon name="fas fa-arrow-right-from-bracket"/>
          </q-item-section>
          <q-item-section>
            <q-item-label>{{ loginData.user.name }} abmelden</q-item-label>
          </q-item-section>
        </q-item>

        <q-item>
          Version {{ appVersion }}
        </q-item>

        <q-item v-if="env.NODE_ENV = 'development'">
          BackendUrl: {{ env.VUE_APP_BACKEND_URL }}
        </q-item>
        <q-item v-if="env.NODE_ENV = 'development'">
          {{ env }}
        </q-item>

      </q-list>
    </q-drawer>

    <q-page-container>
      <router-view/>
    </q-page-container>
  </q-layout>
</template>

<script>
import {ref} from 'vue'
import {LocalStorage} from 'quasar'
import router from '@/router'
import {version} from '../../package.json'

export default {
  name: 'LayoutDefault',

  setup() {
    const logout = function () {
      console.log('Logout ....')
      LocalStorage.remove('auth_token')
      router.push({name: 'login'})
    }
    const appVersion = version

    const loginData = LocalStorage.has('login_data') ? LocalStorage.getItem('login_data') : null
    const countCustomers = loginData.customers ? loginData.customers.length : 0
    const customerSlug = countCustomers === 1 ? loginData.customers[0].slug_name : null

    const isAdmin = ref(loginData.user.is_admin)
    const token = LocalStorage.has('auth_token') ? LocalStorage.getItem('auth_token') : ''
    return {
      appVersion,
      isAdmin,
      loginData,
      customerSlug,
      countCustomers,
      env: process.env,
      leftDrawerOpen: ref(false),
      logout,
      token
    }
  }
}
</script>
