import Notify from 'quasar/src/plugins/Notify.js';

export default {
  methods: {
    createNotify(msg, type) {
      Notify.create({
        group: msg + type,
        message: msg,
        position: 'top-right',
        timeout: 1000 * 60,
        classes: 'notify-with-big',
        type: type,
        actions: [
          {
            icon: 'fas fa-close',
            color: 'white',
            handler: () => {
            }
          }
        ]
      })
    },
    errorNotify(msg) {
      this.createNotify(msg, 'negative')
    },
    successNotify(msg) {
      this.createNotify(msg, 'positive')
    },
    infoNotify(msg) {
      this.createNotify(msg, 'info')
    }
  }
}
