<template>
  <div class="q-pa-md">
    <div class="q-px-lg">
      <q-input v-model="query"
               label="Suchbegriff"
               bottom-slots>
        <template v-slot:hint>
          Suche über alle Artikel deren Lieferanten Ihnen zugeordnet sind
        </template>
        <template v-slot:append>
          <q-icon @click="searchProducts()" class="fa fa-search"/>
        </template>
      </q-input>

      <q-table class="q-mt-lg"
               dense
               :rows="foundProducts"
               :columns="columns"
               :pagination="{rowsPerPage: 20}">
        <template v-slot:body-cell-vendor_name="props">
          <q-td :props="props">
            <span>{{ props.row.vendor.name }}, {{ props.row.vendor.street}}, {{props.row.vendor.country}}-{{props.row.vendor.zipcode}} {{props.row.vendor.city}}</span>
          </q-td>
        </template>
      </q-table>

    </div>
  </div>
</template>

<script>
import {ref, watchEffect} from 'vue'
import HogastWebDataService from '@/services/HogastWebDataService'

export default {
  name: 'ProductSearch',
  setup() {
    const query = ref('')
    const foundProducts = ref([])

    const columns = [
      {name: 'number', label: 'ArtNr', field: 'number', align: 'left', sortable: true},
      {name: 'name', label: 'Bezeichnung', field: 'name', align: 'left', sortable: true},
      {name: 'unit', label: 'EH', field: 'unit', align: 'left', sortable: true},
      {name: 'count_prices', label: '# Preise', field: 'count_prices', align: 'right', sortable: true},
      {
        name: 'vendor_name',
        label: 'Lieferant',
        field: 'vendor_name',
        align: 'left',
        sortable: true
      }
    ]

    let timeoutSearch = null

    watchEffect(() => {
      console.log('The new settings are', query)
      onChange()
    })

    function onChange() {
      if (query.value) {
        console.log('start timeout')
        if (timeoutSearch) {
          clearTimeout(timeoutSearch)
          timeoutSearch = null
        }
        timeoutSearch = setTimeout(function () {
          searchProducts(query.value)
        }, 1500)
      }
    }

    function searchProducts() {
      console.log('search', query.value)
      HogastWebDataService.searchProducts(query.value)
        .then(response => {
          foundProducts.value = response.data
          console.log('found', response.data)
        })
        .catch(e => {
          console.log('error', e)
        })
    }

    return {
      query,
      columns,
      onChange,
      searchProducts,
      foundProducts
    }
  }
}
</script>

<style scoped>

</style>
