<template>
  <div class="q-pa-md" v-if="!isLoading">
    <q-table
      :title="tableTitle"
      dense
      :rows="filteredVendorProducts"
      :columns="columns"
      :pagination="pagination"
      row-key="slug_name">

      <template v-slot:body-cell-count_prices="props">
        <q-td :props="props">
          <q-btn v-if="props.row.count_prices || props.row.count_prices > 0" size="xs" dense round flat color="blue" @click="getPrices(props.row)" style="font-size: .76rem">
            {{ props.row.count_prices }}
          </q-btn>
        </q-td>
      </template>
      <template v-slot:top-right>
        <q-input dense v-model="filterText" placeholder="Filtern">
          <template v-slot:append>
            <q-icon name="fa-solid fa-magnifying-glass" size="xs"></q-icon>
          </template>
        </q-input>
      </template>

    </q-table>
    <gkswBackbutton></gkswBackbutton>
  </div>

</template>
<script>
import HogastWebDataService from '@/services/HogastWebDataService'
import {computed, ref} from 'vue'
import gkswProductPrices from '@/components/ProductPrices'
import gkswBackbutton from '@/components/Backbutton'
import {LocalStorage, useQuasar} from 'quasar'

export default {
  name: 'ProductList',
  components: {
    gkswBackbutton
  },
  setup() {
    const $q = useQuasar()
    const pagination = {rowsPerPage: 15}
    const vendorName = LocalStorage.getItem('current_vendor_name')
    const tableTitle = `Produkte des Lieferanten ${vendorName}`
    const vendorProducts = ref([])
    const productPrices = ref([])
    const isLoading = ref(false)
    const optionsDate = {year: 'numeric', month: '2-digit', day: '2-digit'}
    const optionsDatetime = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    }

    const columns = [
      {name: 'number', label: 'ArtNr', field: 'number', align: 'left', sortable: true},
      {name: 'name', label: 'Bezeichnung', field: 'name', align: 'left', sortable: true},
      {name: 'unit', label: 'Einheit', field: 'unit', align: 'left', sortable: true},
      {name: 'ean_code', label: 'EAN', field: 'ean_code', align: 'left', sortable: true},
      {
        name: 'count_prices',
        label: '# Preise',
        field: 'count_prices',
        align: 'right',
        sortable: true
      }
    ]

    const getPrices = function (data) {
      HogastWebDataService.getProductPrices(data.id)
        .then(response => {
          this.productPrices = response.data
          $q.dialog({
            component: gkswProductPrices,
            componentProps: {
              productPrices: this.productPrices,
              productInfo: `Preise für ${data.number} ${data.name}`
            }
          })
        })
        .catch(e => {
          console.error(e)
        })
        .finally(e => {
          //
        })
    }

    function retrieveProducts(vendorNumber) {
      console.log('retrieveProducts', vendorNumber)
      $q.loading.show({message: 'Bitte warten, Produkte werden geladen...'})
      HogastWebDataService.getVendorProducts(vendorNumber)
        .then(response => {
          console.log('response ', response.data)
          vendorProducts.value = response.data
        })
        .catch(e => {
          console.error(e)
        })
        .finally(e => {
          $q.loading.hide()
        })
    }

    const filterText = ref('')

    const filteredVendorProducts = computed(() => {
      if (filterText.value) {
        const lowerFilterText = filterText.value.toLowerCase()
        console.log('filteredVendorProducts', filteredVendorProducts)
        return vendorProducts.value.filter(row => {
          console.log(row)
          return (
            (row.number ? row.number.toLowerCase().includes(lowerFilterText) : false) ||
            (row.name ? row.name.toLowerCase().includes(lowerFilterText) : false) ||
            (row.ean_code ? row.ean_code.toLowerCase().includes(lowerFilterText) : false)
          )
        })
      }
      return vendorProducts.value
    })

    return {
      filterText,
      tableTitle,
      pagination,
      vendorProducts,
      filteredVendorProducts,
      columns,
      isLoading,
      productPrices,
      optionsDate,
      optionsDatetime,
      retrieveProducts,
      getPrices
    }
  },
  mounted() {
    this.vendorName = this.$route.params.vendor_name
    this.retrieveProducts(this.$route.params.vendor_number)
  }
}
</script>
