<template>
  <div class="q-mt-md">
    <q-btn
      class="q-px-lg"
      color="primary"
      size="md"
      dense
      @click="$router.go(-1)">Zurück
    </q-btn>
  </div>
</template>

<script>
export default {
  name: 'gkswBackbutton'
}
</script>
