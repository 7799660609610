<template>
  <div class="q-pa-md flex justify-center">
    <q-card bordered class="q-ma-xl flex justify-center error_card">
      <q-card-section>
        <div class="text-h6">Ein Fehler ist aufgetreten :-(</div>
        <div class="">Der Fehler trat auf in der Kommunikation mit dem Server auf.</div>
      </q-card-section>
      <q-separator class="full-width"></q-separator>
      <q-card-section class="full-width">
        <div class="row">
          <div class="col-2">Code:</div>
          <div class="col">{{ errorCode }}</div>
        </div>
        <q-separator class="q-my-lg"></q-separator>
        <div class="row">
          <div class="col-2">Meldung:</div>
          <div class="col">{{ errorMessage }}</div>
        </div>
      </q-card-section>
      <q-separator class="full-width"></q-separator>
      <q-card-actions align="around">
        <q-btn @click="back()" label="Zurück" color="primary" class="q-px-lg"></q-btn>
      </q-card-actions>
    </q-card>

  </div>
</template>

<script>
import router from '@/router'
import {ref, toRef} from 'vue'

export default {
  props: {
    error: {required: true}
  },
  name: 'ErrorPage',
  setup(props) {
    const errorObj = toRef(props, 'error')
    const errorInfo = JSON.parse(errorObj.value)
    const errorCode = ref(errorInfo.statusCode + ' ' + errorInfo.statusText)
    const errorMessage = ref(errorInfo.statusMsg)

    if (errorInfo.data && errorInfo.data.detail) {
      errorMessage.value = errorMessage.value ? '\n' : '' + errorInfo.data.detail
    }

    function back() {
      router.back()
    }

    return {
      back,
      errorCode,
      errorMessage
    }
  }
}
</script>

<style scoped>
  .error_card {
    width: 450px;
  }
</style>
