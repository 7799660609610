
import './styles/quasar.sass'
import iconSet from 'quasar/icon-set/fontawesome-v6.js'
import lang from 'quasar/lang/de.js'
import '@quasar/extras/fontawesome-v6/fontawesome-v6.css'
import LocalStorage from 'quasar/src/plugins/LocalStorage.js';import Dialog from 'quasar/src/plugins/Dialog.js';import Notify from 'quasar/src/plugins/Notify.js';import Loading from 'quasar/src/plugins/Loading.js';

// To be used on app.use(Quasar, { ... })
export default {
  config: {
    Loading
  },
  plugins: {
    Dialog,
    Notify,
    LocalStorage,
    Loading
  },
  lang: lang,
  iconSet: iconSet
}
