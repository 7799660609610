import script from "./Dashboard.vue?vue&type=script&setup=true&lang=js"
export * from "./Dashboard.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QMarkupTable from 'quasar/src/components/markup-table/QMarkupTable.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QTable from 'quasar/src/components/table/QTable.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QMarkupTable,QSeparator,QTable});
