import {createRouter, createWebHistory} from 'vue-router'
import DefaultLayout from './layouts/Default.vue'
import About from './views/About.vue'
import ErrorPage from '@/components/ErrorPage'
import CustomerList from '@/components/CustomerList'
import VendorList from '@/components/VendorList'
import UpdateList from '@/components/UpdateList'
import Login from '@/components/Login'
import LocalStorage from 'quasar/src/plugins/LocalStorage.js';
import ProductList from '@/components/ProductList'
import VendorPricelist from '@/components/VendorPricelist'
import ProductSearch from '@/components/ProductSearch'
import Dashboard from '@/components/Dashboard'
import Http404 from '@/components/Http404'

const routes = [
  {
    path: '/',
    name: 'home',
    component: DefaultLayout,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'customers/',
        name: 'customers',
        component: CustomerList,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/customer/:customer_slug/',
        name: 'vendor-list',
        component: VendorList
      },
      {
        path: '/customer/:customer_slug/vendor/:vendor_number/products/',
        name: 'vendor-product-list',
        component: ProductList
      },
      {
        path: '/customer/:customer_slug/vendor/:vendor_number/updates/',
        name: 'vendor-update-list',
        component: UpdateList
      },
      {
        path: '/customer/:customer_slug/vendor/:vendor_number/pricelist/',
        name: 'vendor-pricelist',
        component: VendorPricelist
      },
      {
        path: '/products/search/',
        name: 'product-search',
        component: ProductSearch
      },
      {
        path: '/about',
        name: 'about',
        component: About
      },
      {
        path: '/',
        name: 'dashboard',
        component: Dashboard
      },
      {
        path: '/error',
        name: 'error',
        component: ErrorPage,
        props: true,
        meta: {
          requiresAuth: false
        }
      },
      {
        path: '/:catchAll(.*)',
        component: Http404
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// eslint-disable-next-line no-unused-vars
router.beforeEach((to, from) => {
  console.log('router beforeEach')
  if (to.meta.requiresAuth && !LocalStorage.getItem('auth_token')) {
    return {
      name: 'login'
    }
  }
})
export default router
