<template>
  <div class="q-pa-md">
    <q-table
      title="Meine Lieferanten"
      dense
      :rows="vendors"
      :columns="columns"
      :pagination="{rowsPerPage: 20}"
      @row-click="showPricelist"
      row-key="slug_name">

      <template v-slot:body-cell-count_updates="props">
        <q-td :props="props">
          <q-btn v-if="props.row.count_updates || props.row.count_updates > 0" dense round flat color="blue" @click="gotoUpdates(props.row)" style="font-size: small">
            {{ props.row.count_updates }}
          </q-btn>
        </q-td>
      </template>
      <template v-slot:body-cell-count_products="props">
        <q-td :props="props">
          <q-btn v-if="props.row.count_products" dense round flat color="blue" @click="gotoProducts(props.row)" style="font-size: small">
            {{ props.row.count_products }}
          </q-btn>
        </q-td>
      </template>
      <template v-slot:body-cell-action="props">
        <q-td :props="props">
          <!--
          <q-btn dense round flat size="sm" color="green" class="q-mr-sm" icon="fa-solid fa-hand-holding-dollar" @click="downloadPriceList(props.row)">
            <q-tooltip>Download Preisliste</q-tooltip>
          </q-btn>
          -->
          <q-btn dense round flat size="sm" color="blue" icon="fas fa-cloud-download" @click="loadUpdates(props.row)">
            <q-tooltip>Updates vom Hogast-Server laden</q-tooltip>
          </q-btn>
        </q-td>
      </template>
    </q-table>

    <gkswBackbutton></gkswBackbutton>

  </div>

</template>

<script>
import HogastWebDataService from '../services/HogastWebDataService'
import router from '@/router'
import {ref} from 'vue'
import {LocalStorage, useQuasar} from 'quasar'
import utilities from '@/mixins/utilities'
import {useRoute} from 'vue-router'
import gkswBackbutton from '@/components/Backbutton'

export default {
  name: 'customerDetail',
  components: {
    gkswBackbutton
  },
  setup: () => {
    const route = useRoute()
    const $q = useQuasar()
    const vendors = ref([])
    const pagination = {rowsPerPage: 25}
    const columns = [
      {
        name: 'account_number',
        label: 'Account',
        field: 'account_number',
        align: 'left',
        sortable: true
      },
      {name: 'name', label: 'Name', field: 'name', align: 'left', sortable: true},
      {name: 'street', label: 'Straße', field: 'street', align: 'left', sortable: true},
      {name: 'zipcode', label: 'Plz', field: 'zipcode', align: 'left', sortable: true},
      {name: 'city', label: 'Ort', field: 'city', align: 'left', sortable: true},
      {name: 'country', label: 'Land', field: 'country', align: 'left', sortable: true},
      {name: 'action', label: 'Load', field: 'action', align: 'center'},
      {name: 'count_updates', label: '# Updates', field: 'count_updates', sortable: true},
      {
        name: 'count_updates_loaded',
        label: '# Geladen',
        field: 'count_updates_loaded',
        sortable: true
      },
      {name: 'count_products', label: '# Produkte', field: 'count_products', sortable: true}
    ]

    const getVendors = function (id) {
      $q.loading.show({message: 'Bitte warten, Liefanten werden geladen'})
      HogastWebDataService.getCustomer(id)
        .then(response => {
          this.vendors = response.data
        })
        .catch(e => {
          console.error(e)
        })
        .finally(e => {
          $q.loading.hide()
        })
    }

    function downloadPriceList(data) {
      LocalStorage.set('current_vendor_name', data.name)
      router.push({
        name: 'vendor-pricelist',
        params: {
          customer_slug: this.$route.params.customer_slug,
          vendor_number: data.account_number
        }
      })
    }

    const gotoProducts = function (data) {
      LocalStorage.set('current_vendor_name', data.name)
      router.push({
        name: 'vendor-product-list',
        params: {
          customer_slug: this.$route.params.customer_slug,
          vendor_number: data.account_number
        }
      })
    }

    function loadUpdates(e) {
      $q.loading.show({message: 'Bitte warten, updates werden von Hogast geladen'})
      HogastWebDataService.loadVendorUpdates(e.account_number)
        .then(response => {
          e.count_updates = response.data.count_total
          utilities.methods.successNotify(` ${response.data.count_okay} Updates wurden vom Hogast-Server geladen`)
        })
        .catch(e => {
          utilities.methods.errorNotify(`Fehler: ${e.response.status} ${e.response.statusText} / ${e.response.data.detail}`)
        })
        .finally(e => {
          $q.loading.hide()
        })
    }

    const gotoUpdates = function (e) {
      LocalStorage.set('current_vendor_name', e.name)
      router.push({
        name: 'vendor-update-list',
        params: {
          customer_slug: this.$route.params.customer_slug,
          vendor_number: e.account_number
        }
      })
    }

    function showPricelist(evt, row) {
      if (evt.target.nodeName === 'TD') {
        LocalStorage.set('current_vendor_name', row.name)
        if (row.count_products) {
          router.push({
            name: 'vendor-pricelist',
            params: {
              customer_slug: route.params.customer_slug,
              vendor_number: row.account_number
            }
          })
        }
      }
    }

    return {
      vendors,
      columns,
      pagination,
      showPricelist,
      downloadPriceList,
      getVendors,
      gotoProducts,
      loadUpdates,
      gotoUpdates
    }
  },
  mounted() {
    this.getVendors(this.$route.params.customer_slug)
  }
}
</script>
