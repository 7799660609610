import axios from 'axios'
import router from '@/router'
import LocalStorage from 'quasar/src/plugins/LocalStorage.js';

const httpHeader = {
  'Content-Type': 'application/json',
  Accept: '*/*'
}

const httpClient = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_URL,
  headers: httpHeader
})

httpClient.interceptors.request.use((config) => {
  if (LocalStorage.has('auth_token')) {
    config.headers.Authorization = `Token ${LocalStorage.getItem('auth_token')}`
  }
  return config
})

httpClient.interceptors.response.use(
  response => response,
  error => {
    console.log('interceptor', error)
    const errorInfo = {
      statusCode: '?',
      statusText: '',
      statusMsg: '',
      data: {}
    }

    if (error.response) {
      // Request made and server responded
      errorInfo.statusCode = error.response.status
      errorInfo.statusText = error.response.statusText
      errorInfo.data = error.response.data
    } else {
      // Something happened in setting up the request that triggered an Error
      errorInfo.statusMsg = error.message
    }
    router.push({
      name: 'error',
      params: {error: JSON.stringify(errorInfo)}
    })
    return Promise.reject(error)
  })

export default httpClient
