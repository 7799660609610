<template>

  <gksw-preislisten-filterbox/>

  <div class="q-pa-md">
    <q-table
      :title="tableTitle"
      dense
      :rows="priceList"
      :columns="columns"
      :pagination="{rowsPerPage: 20}"
      row-key="slug_name">
      <template v-slot:body-cell-number="props">
        <q-td>
          <router-link to="#" @click="getPrices(props.row)">
            {{ props.row.number }}
          </router-link>
        </q-td>
      </template>
      <template v-slot:top-left>
        <span class="text-subtitle1">
        {{ tableTitle }}
          </span>
      </template>

      <template v-slot:top-right>
        <div class="flex q-gutter-md">

          <q-btn
            color="secondary"
            label="Filter"
            icon-right="fa-solid fa-filter"
            no-caps
            @click="showFilterDialog"
          >
          </q-btn>

          <q-btn
            color="primary"
            icon-right="fa-solid fa-file-excel"
            label="Als XLSX laden"
            no-caps
            @click="exportTable('xlsx')"
          />
          <q-btn
            color="primary"
            icon-right="fa-solid fa-file-csv"
            label="Als CSV laden"
            no-caps
            @click="exportTable('csv')"
          />
          <q-btn
            color="primary"
            icon-right="fa-solid fa-file"
            label="Als JSON laden"
            no-caps
            @click="exportTable('json')"
          />
        </div>
      </template>

    </q-table>

    <gksw-backbutton></gksw-backbutton>

  </div>
</template>

<script>
import {ref} from 'vue'
import {useRoute} from 'vue-router'
import HogastWebDataService from '../services/HogastWebDataService'
import {date, LocalStorage, useQuasar, useDialogPluginComponent} from 'quasar'
import gkswProductPrices from '@/components/ProductPrices'
import gkswPreislistenFilterbox from '@/components/PreislistenFilterbox'
import GkswBackbutton from '@/components/Backbutton'

export default {
  name: 'VendorPricelist',
  components: {
    GkswBackbutton,
    gkswPreislistenFilterbox
  },
  emits: [
    // REQUIRED; need to specify some events that your
    // component will emit through useDialogPluginComponent()
    ...useDialogPluginComponent.emits
  ],
  setup() {
    const route = useRoute()
    const $q = useQuasar()
    const priceList = ref([])
    const productPrices = ref([])

    const today = new Date()
    const filterOptions = {
      date: date.formatDate(today, 'YYYY-MM-DD'),
      withSpecialOffers: true,
      onlyFuturePrices: true
    }

    const vendorName = LocalStorage.getItem('current_vendor_name')
    const tableTitle = ref('')

    function setListTitle() {
      const t1 = filterOptions.withSpecialOffers ? 'mit Spezialangeboten' : 'ohne Spezialangeboten'
      const t2 = filterOptions.onlyFuturePrices ? 'nur aktuellen Preisen' : 'alle Preisen'
      tableTitle.value = `Preisliste ${vendorName} für ${date.formatDate(filterOptions.date, 'DD.MM.YYYY')} und ${t1} und ${t2}`
    }

    const columns = [
      {name: 'number', label: 'ArtNr', field: 'number', align: 'left', sortable: true},
      {name: 'name', label: 'Bezeichnung', field: 'name', align: 'left', sortable: true},
      {name: 'unit', label: 'EH', field: 'unit', align: 'left', sortable: true},
      {name: 'unit_factor', label: 'Faktor', field: 'unit_factor', align: 'left', sortable: true},
      {
        name: 'price',
        label: 'Preis',
        field: 'price',
        align: 'right',
        sortable: true,
        format: val => parseFloat(val || '0').toLocaleString('de-DE', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })
      },
      {
        name: 'valid_from',
        label: 'Gültig ab',
        field: 'valid_from',
        align: 'left',
        sortable: true,
        format: val => date.formatDate(val, 'DD.MM.YYYY')
      },
      {
        name: 'valid_to',
        label: 'Gültig bis',
        field: 'valid_to',
        align: 'left',
        sortable: true,
        format: val => date.formatDate(val, 'DD.MM.YYYY')
      },
      {
        name: 'special_offer',
        label: 'Spezialpreis',
        field: 'special_offer',
        align: 'left',
        sortable: true
      },
      {
        name: 'from_amount',
        label: 'Ab Menge',
        field: 'from_amount',
        align: 'right',
        sortable: true
      },
      {name: 'price_mode', label: 'Preisart', field: 'price_mode', align: 'left', sortable: true}

    ]

    function showFilterDialog() {
      $q.dialog({
        component: gkswPreislistenFilterbox,
        componentProps: {
          filterOptions: filterOptions
        }
      }).onOk(data => {
        if (filterOptions.date !== data.date || filterOptions.withSpecialOffers !== data.withSpecialOffers || filterOptions.onlyFuturePrices !== data.onlyFuturePrices) {
          filterOptions.date = data.date
          filterOptions.withSpecialOffers = data.withSpecialOffers
          filterOptions.onlyFuturePrices = data.onlyFuturePrices
          loadPricelist()
        }
      })
    }

    const getPrices = function (data) {
      HogastWebDataService.getProductPrices(data.product_id)
        .then(response => {
          $q.dialog({
            component: gkswProductPrices,
            componentProps: {productPrices: response.data}
          })
        })
        .catch(e => {
          console.error(e)
        })
    }

    function showUpdates() {
      console.log('showUpdates')
    }

    function exportTable(exportFormat) {
      const vendorNumber = route.params.vendor_number
      const forWhichDate = new Date().toISOString()
      HogastWebDataService.loadPricelist(vendorNumber, {
        special_price: true,
        export_format: exportFormat,
        date: forWhichDate
      }, true).then(response => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]))
        const fileLink = document.createElement('a')
        const fileName = response.headers['gksw-filename']
        fileLink.href = fileURL
        fileLink.setAttribute('download', fileName)
        document.body.appendChild(fileLink)
        fileLink.click()
      }).catch(e => {
        console.error(e)
      })
    }

    function loadPricelist() {
      $q.loading.show({
        message: 'Bitte warten, die Preisliste wird erstellt und geladen'
      })
      HogastWebDataService.loadPricelist(route.params.vendor_number, {
        special_price: filterOptions.withSpecialOffers,
        only_future_prices: filterOptions.onlyFuturePrices,
        export_format: 'json',
        date: filterOptions.date
      }, false)
        .then(response => {
          setListTitle()
          priceList.value = response.data
        })
        .catch(e => {
          console.error('downloadPricelist catch', e)
        })
        .finally(e => {
          $q.loading.hide()
        })
    }

    return {
      filterOptions,
      priceList,
      columns,
      tableTitle,
      getPrices,
      showUpdates,
      showFilterDialog,
      productPrices,
      exportTable,
      loadPricelist
    }
  },
  mounted() {
    this.loadPricelist()
  }
}

</script>

<style scoped>

</style>
