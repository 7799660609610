<template>
  <q-dialog ref="dialogRef" @hide="onDialogHide">
    <q-card class="q-dialog-plugin full-width"
            style="max-width: 50%; height: 560px"
            id="table-product-prices">
      <q-splitter v-model="splitterModel">
        <template v-slot:before>
          <q-tabs
            v-model="tab"
            vertical
            active-color="primary"
            indicator-color="primary"
            align="justify"
            class="q-mt-lg"
          >
            <q-tab name="list" icon="fa-solid fa-list" label="Liste"></q-tab>
            <q-tab name="line" icon="fa-solid fa-chart-line" label="Chart"></q-tab>
          </q-tabs>
        </template>

        <template v-slot:after>
          <div class="q-pt-md q-ml-md text-subtitle1">{{ productInfo }}</div>
          <q-tab-panels
            style="height: 470px"
            v-model="tab"
            animated
            swipeable
            vertical
            transition-prev="jump-up"
            transition-next="jump-up"
          >
            <q-tab-panel name="list" style="height: 470px">
              <q-table
                class="full-height"
                dense
                :rows="productPrices"
                :columns="columns"
                :pagination="{rowsPerPage: 15}"
                row-key="id">
              </q-table>
            </q-tab-panel>
            <q-tab-panel name="line" style="height: 470px">
              <apexchart
                :type="chartType"
                width="98%"
                height="95%"
                :options="chartOptions"
                :series="chartSeries"
              ></apexchart>

            </q-tab-panel>
          </q-tab-panels>
        </template>

      </q-splitter>
      <q-card-actions align="right">
        <q-btn color="primary" size="sm" label="OK" v-close-popup/>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import {useDialogPluginComponent, date} from 'quasar'
import {ref} from 'vue'
import VueApexCharts from 'vue3-apexcharts'

export default {
  name: 'gkswProductPrices',
  components: {
    apexchart: VueApexCharts
  },
  props: {
    productPrices: Array,
    productInfo: String
  },
  emits: [
    ...useDialogPluginComponent.emits
  ],
  setup(props) {
    const {dialogRef, onDialogHide, onDialogOK, onDialogCancel} = useDialogPluginComponent()

    const columns = [
      {name: 'update', field: 'update', label: 'UpdateId', align: 'left', sortable: true},
      {
        name: 'update_trans_date',
        field: 'update_trans_date',
        label: 'Datum/Uhrzeit',
        align: 'left',
        sortable: true,
        format: val => date.formatDate(val, 'DD.MM.YYYY HH:mm:ss')
      },
      {
        name: 'valid_from',
        field: 'valid_from',
        label: 'Gültig von',
        align: 'left',
        sortable: true,
        format: val => date.formatDate(val, 'DD.MM.YYYY')
      },
      {
        name: 'valid_to',
        field: 'valid_to',
        label: 'Gültig bis',
        align: 'left',
        sortable: true,
        format: val => date.formatDate(val, 'DD.MM.YYYY')
      },
      {name: 'unit', field: 'unit', label: 'EH', align: 'left', sortable: true},
      {
        name: 'special_offer',
        field: 'special_offer',
        label: 'Spezialpreis',
        align: 'left',
        sortable: true
      },
      {
        name: 'from_amount',
        field: 'from_amount',
        label: 'Ab Menge',
        align: 'right',
        sortable: true
      },
      {
        name: 'price',
        field: 'price',
        label: 'Preis',
        align: 'right',
        sortable: true,
        format: val => parseFloat(val).toLocaleString('de-DE', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })

      }
    ]

    const tab = ref('list')

    const chartType = 'line'
    const chartOptions = {
      chart: {
        id: 'char-prices'
      },
      dataLabels: {
        enabled: true
      },
      yaxis: {
        type: 'numeric'
      },
      xaxis: {
        type: 'datetime',
        labels: {
          formatter: function (value, timestamp) {
            return value ? new Date(value).toLocaleDateString() : ''// The formatter function overrides format property
          }
        }
      }
    }
    const chartSeries = []

    const groupByCategory = props.productPrices.reduce((group, productPrice) => {
      const key = productPrice.special_offer + '|' + productPrice.from_amount
      group[key] = group[key] ?? []
      const productPriceList = {x: productPrice.valid_from, y: productPrice.price}
      const lastProductPricelist = group[key] ? group[key][group[key].length - 1] : {}
      if ((!lastProductPricelist || lastProductPricelist.y !== productPriceList.y) && productPriceList.y !== 0) {
        console.log('add', key, productPriceList, lastProductPricelist)
        group[key].push(productPriceList)
      }
      return group
    }, {})

    Object.keys(groupByCategory).forEach(function (key) {
      const serie = {name: key, data: groupByCategory[key]}
      if (key === 'N|0') {
        serie.name = 'Normalpreis'
        serie.type = 'area'
      } else {
        const keyValues = key.split('|')
        serie.name = keyValues[0] === 'N' ? 'Normalpreise' : 'Sonderpreis'
        if (keyValues[1] !== '0') {
          serie.name = serie.name + 'ab ' + keyValues[1] + ' EH'
        }
      }
      chartSeries.push(serie)
    })

    console.log('series', chartSeries)

    return {
      chartType,
      chartOptions,
      chartSeries,
      tab,
      splitterModel: ref(10),
      columns,
      dialogRef,
      onDialogHide,
      onDialogOK,
      onDialogCancel
    }
  }

}
</script>

<style>
#table-product-prices > .q-table__card .q-table__middle {
  flex: 1 1 auto;
  min-height: 300px;
}

#table-product-prices > .q-table__container .q-table__middle {
  min-height: 350px;
}
</style>
