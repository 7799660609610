<template>
  <q-dialog persistent ref="dialogRef">
    <div style="width: 350px">
      <q-card class="q-dialog-plugin full-width" id="table-productprices-filter">
        <div class="flex column q-pa-lg">
          <q-date
            v-model="filterDate"
            :date="filterDate"
            mask="YYYY-MM-DD"
            minimal
          />

          <q-checkbox v-model="filterWithSpecialOffers" label="Mit Spezialangeboten"/>
          <q-checkbox v-model="filterOnlyFuturePrices" label="Nur aktuelle und zukünftige Preise"/>

        </div>
        <q-card-actions align="right">
          <q-btn color="primary" size="sm" label="OK" @click="onOKClick"/>
        </q-card-actions>
      </q-card>

    </div>
  </q-dialog>
</template>

<script>
import {ref} from 'vue'
import {useDialogPluginComponent} from 'quasar'

export default {
  name: 'gkswPreislistenFilterbox',
  props: {
    filterOptions: null
  },
  emits: [
    ...useDialogPluginComponent.emits
  ],
  setup(props) {
    console.log('filterbox setup ', props.filterOptions)

    const filterDialog = ref(null)
    const filterDate = ref(null)
    const filterWithSpecialOffers = ref(false)
    const filterOnlyFuturePrices = ref(false)

    const { dialogRef, onDialogHide, onDialogOK } = useDialogPluginComponent()

    function show() {
      filterDate.value = props.filterOptions.date
      filterWithSpecialOffers.value = props.filterOptions.withSpecialOffers
      filterOnlyFuturePrices.value = props.filterOptions.onlyFuturePrices
      dialogRef.value.show()
    }

    function onOKClick() {
      console.log('onOKClick', filterDate, filterWithSpecialOffers, filterOnlyFuturePrices)
      const result = {
        date: filterDate.value,
        withSpecialOffers: filterWithSpecialOffers.value,
        onlyFuturePrices: filterOnlyFuturePrices.value
      }
      onDialogOK(result)
    }

    return {
      dialogRef,
      onDialogHide,
      onOKClick,
      show,
      filterDialog,
      filterDate,
      filterWithSpecialOffers,
      filterOnlyFuturePrices
    }
  }
}
</script>
