<template>
  <div>
    <q-page padding class="flex justify-center">
      <img src="../assets/error-404.png" alt="">
      <h1>404 Seite nicht gefunden</h1>
    </q-page>
  </div>
</template>

<script>
export default {
  name: 'Http404'
}
</script>

<style scoped>

</style>
