<template>
  <div class="q-pa-md">
    <q-page padding>
      <div>
        {{ loginData }}
      </div>
    </q-page>
  </div>
</template>

<script setup>
import {LocalStorage} from 'quasar'

const loginData = LocalStorage.getItem('login_data')

</script>
