import { createApp } from 'vue'
import App from './App.vue'

import Quasar from 'quasar/src/vue-plugin.js';import LocalStorage from 'quasar/src/plugins/LocalStorage.js';import SessionStorage from 'quasar/src/plugins/SessionStorage.js';import Dialog from 'quasar/src/plugins/Dialog.js';import Notify from 'quasar/src/plugins/Notify.js';
import quasarUserOptions from './quasar-user-options'
import {createPinia} from 'pinia'
import router from './router'

const app = createApp(App)
const pinia = createPinia()

app.use(pinia)
app.use(router)
app.use(Quasar, quasarUserOptions, {
  plugins: {
    Dialog,
    Notify,
    LocalStorage,
    SessionStorage
  },
  config: {
    Notify
  }
})

app.mount('#app')
