<template>
  <div id="q-app">
    <q-layout>
      <q-page class="window-height window-width row justify-center q-pt-xl">
        <div class="column q-pa-lg">
          <div class="row">
            <q-card square class="shadow-24" style="width:400px;height:380px;">
              <q-card-section class="bg-deep-purple-7">
                <h4 class="text-h5 text-white q-my-md">
                  <q-icon name="fas fa-arrow-right-to-bracket"/>
                  {{ title }}
                </h4>
              </q-card-section>
              <q-card-section>
                <q-input
                  ref="email"
                  round
                  v-model="username"
                  type="email"
                  lazy-rules
                  :rules="[]"
                  label="Benutzername">
                  <template v-slot:prepend>
                    <q-icon name="fas fa-user"/>
                  </template>
                </q-input>
                <q-input
                  ref="userStore._password"
                  square
                  v-model="password"
                  :type="passwordFieldType"
                  lazy-rules
                  :rules="[]"
                  label="Passwort">
                  <template v-slot:prepend>
                    <q-icon name="fas fa-lock"/>
                  </template>
                  <template v-slot:append>
                    <q-icon
                      :name="visibilityIcon" @click="switchVisibility()" class="cursor-pointer"/>
                  </template>
                </q-input>
                <div class="">
                  <p class="text-red-14"> {{ message }}</p>
                </div>
              </q-card-section>
              <q-card-actions class="q-px-lg">
                <q-btn
                  unelevated
                  color="secondary"
                  @click="login()"
                  class="full-width text-white">Anmelden
                </q-btn>
              </q-card-actions>
            </q-card>
          </div>
        </div>
      </q-page>
    </q-layout>
  </div>

</template>

<script>
import HogastWebDataService from '@/services/HogastWebDataService'
import {LocalStorage} from 'quasar'
import {ref, defineComponent} from 'vue'
import router from '@/router'

export default defineComponent({
  name: 'Login',
  setup: () => {
    const message = ref('')
    const title = 'Anmelden'
    const passwordFieldType = ref('password')
    const visibilityIcon = ref('fas fa-eye')
    const visibility = ref(false)
    const username = ref(LocalStorage.getItem('username'))
    const password = ref(LocalStorage.getItem('password'))

    /* Login function */
    const login = function () {
      console.log('login', this.username, this.password)
      HogastWebDataService.postLogin(this.username, this.password)
        .then(response => {
          LocalStorage.set('auth_token', response.data.token)
          LocalStorage.set('login_data', response.data)
          LocalStorage.set('username', this.username)
          LocalStorage.set('password', this.password)
          console.log(response.data)
        })
        .catch(e => {
          console.log('catch', e)
          if (e.response) {
            message.value = e.response.data.msg
          } else {
            message.value = e
          }
          console.error('catch login', message.value)
        })
        .finally(e => {
          console.log('finally of login with token ', LocalStorage.getItem('auth_token'))
          router.push({path: '/'})
        })
    }

    /* show password */
    const switchVisibility = function () {
      this.visibility = !this.visibility
      this.passwordFieldType = this.visibility ? 'text' : 'password'
      this.visibilityIcon = this.visibility ? 'fas fa-eye-slash' : 'fas fa-exe'
      console.log('switchVisibility', this.visibility, this.passwordFieldType)
    }

    return {
      login,
      switchVisibility,
      username,
      password,
      title,
      passwordFieldType,
      visibilityIcon,
      visibility,
      message
    }
  }
})
</script>

<style scoped>

</style>
