<template>

  <div class="q-pa-md">
    <q-table
      :title="tableTitle"
      dense
      :rows="vendorUpdates"
      :columns="columns"
      :pagination="{rowsPerPage: 25}"
      row-key="id">
      <template v-slot:top-right>
        <q-btn label="Alle laden" @click="loadAllUpdates" v-if="somethingToLoad"></q-btn>
        <q-btn label="Alle verarbeiten" @click="processAllUpdates" v-if="somethingToProcess"></q-btn>
      </template>
      <template v-slot:body-cell-item_data_loaded="props">
        <q-td :props="props">
          <q-icon v-if="props.row.item_data_loaded" name="fas fa-check" class="text-green-7"></q-icon>
          <q-btn v-else dense round flat icon="fa-solid fa-cloud-arrow-down" class="text-blue" @click="loadItems(props.row)"></q-btn>
        </q-td>
      </template>
      <template v-slot:body-cell-item_data_processed="props">
        <q-td :props="props">
          <q-icon v-if="props.row.item_data_processed && props.row.item_data_loaded" name="fas fa-check" class="fa-2 text-green-7"></q-icon>
          <q-btn v-if="props.row.item_data_loaded && !props.row.item_data_processed" dense round flat icon="fa-solid fa-cogs" @click="processItems(props.row)" class="text-blue" style="font-size: smaller"></q-btn>
        </q-td>
      </template>

    </q-table>
    <gksw-backbutton></gksw-backbutton>
  </div>

</template>

<script>
import HogastWebDataService from '@/services/HogastWebDataService'
import {date, LocalStorage, useQuasar} from 'quasar'
import {ref} from 'vue'
import utilities from '@/mixins/utilities'
import {useRoute} from 'vue-router'
import GkswBackbutton from '@/components/Backbutton'

export default {
  name: 'UpdateList',
  components: {GkswBackbutton},
  setup() {
    const route = useRoute()
    const $q = useQuasar()
    const vendorName = ref(LocalStorage.getItem('current_vendor_name'))
    const vendorUpdates = ref([])
    const somethingToLoad = ref(false)
    const somethingToProcess = ref(false)
    const columns = [
      {name: 'update_id', label: 'UpdateId', field: 'update_id', align: 'left', sortable: true},
      {name: 'type', label: 'Typ', field: 'type', align: 'left', sortable: true},
      {
        name: 'trans_date',
        label: 'Datum/Uhrzeit',
        field: 'trans_date',
        align: 'left',
        sortable: true,
        format: val => date.formatDate(val, 'DD.MM.YYYY HH:mm:ss')
      },
      {
        name: 'update_part',
        label: 'UpdatePart',
        field: 'update_part',
        align: 'left',
        sortable: true
      },
      {
        name: 'count_products',
        label: '# Produkte',
        field: 'count_products',
        align: 'right',
        sortable: true
      },
      {
        name: 'count_prices',
        label: '# Preise',
        field: 'count_prices',
        align: 'right',
        sortable: true
      },
      {
        name: 'item_data_loaded',
        label: 'Geladen',
        field: 'item_data_loaded',
        align: 'center',
        sortable: true
      },
      {
        name: 'item_data_processed',
        label: 'Verarbeitet',
        field: 'item_data_processed',
        align: 'left',
        sortable: true,
        format: val => date.formatDate(val, 'DD.MM.YYYY HH:mm:ss')
      }
    ]
    const tableTitle = `Liste der vorhandenen Updates von ${vendorName.value}`

    const retrieveUpdates = function (vendorNumber) {
      $q.loading.show({message: 'Updates werden vom Hogast Server geladen'})
      HogastWebDataService.getUpdates(vendorNumber)
        .then(response => {
          vendorUpdates.value = response.data
        })
        .catch(e => {
          console.error(e)
        })
        .finally(_ => {
          somethingToLoad.value = vendorUpdates.value.some(item => {
            return (!item.item_data_processed && !item.item_data_loaded)
          })
          somethingToProcess.value = vendorUpdates.value.some(function (item, index, array) {
            return (!item.item_data_processed && item.item_data_loaded)
          })
          console.log(somethingToLoad.value, somethingToProcess.value)
          $q.loading.hide()
        })
    }

    const loadItems = function (data) {
      $q.loading.show({message: 'Updates werden geladen'})
      HogastWebDataService.loadUpdates(null, data.update_id)
        .then(response => {
          utilities.methods.successNotify('Update wurden von Hogast geladen')
        })
        .catch(e => {
          console.error(e)
          utilities.methods.errorNotify('Fehler beim Laden des Updates')
        })
        .finally(e => {
          retrieveUpdates(route.params.vendor_number)
          $q.loading.hide()
        })
    }

    function processItems(data) {
      $q.loading.show({message: 'Updates werden verarbeitet'})
      HogastWebDataService.processUpdate(data.update_id)
        .then(response => {
          utilities.methods.successNotify('Update wurden erfolgreich verarbeitet')
          console.log('processItems', data.update_id, response.data)
        })
        .catch(e => {
          console.error(e)
          utilities.methods.errorNotify('Fehler beim vearbeiten ' + JSON.stringify(e))
        })
        .finally(e => {
          retrieveUpdates(route.params.vendor_number)
          $q.loading.hide()
        })
    }

    function loadAllUpdates() {
      $q.loading.show({message: 'Bitte warten, es werden alle Updates vom Hogast Service geladen'})
      const promises = []
      vendorUpdates.value.filter(item => !item.item_data_loaded).forEach((item, index) => {
        console.log('in loop', route.params.vendor_number, item.update_id, item)
        promises.push(HogastWebDataService.loadUpdates(null, item.update_id))
      })
      Promise.all(promises).then(results => {
        console.log('promises all', promises, results)
      }).finally(e => {
        retrieveUpdates(route.params.vendor_number)
        $q.loading.hide()
      })
    }

    function processAllUpdates() {
      $q.loading.show({message: 'Bitte warten, es werden alle Updates verarbeitet'})
      const promises = []
      vendorUpdates.value.filter(item => item.item_data_loaded && !item.item_data_processed).forEach((item, index) => {
        promises.push(HogastWebDataService.processUpdate(item.update_id))
      })
      console.log('promises', promises)
      Promise.all(promises).then(response => {
        console.log('promises all', response.data)
      }).finally(_ => {
        retrieveUpdates(route.params.vendor_number)
        $q.loading.hide()
      })
    }

    return {
      loadAllUpdates,
      processAllUpdates,
      somethingToProcess,
      somethingToLoad,
      vendorUpdates,
      vendorName,
      columns,
      tableTitle,
      retrieveUpdates,
      loadItems,
      processItems
    }
  },
  mounted() {
    this.retrieveUpdates(this.$route.params.vendor_number)
  }
}
</script>
