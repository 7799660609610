import httpClient from '../http-common'

class HogastWebDataService {
  postLogin(user, password) {
    const formData = new FormData()
    formData.append('username', user)
    formData.append('password', password)
    console.log('formdata', formData)
    return httpClient.post('/login/', formData)
  }

  getCustomers() {
    return httpClient.get('/customer/')
  }

  getCustomer(slug) {
    return httpClient.get(`/customer/${slug}/`)
  }

  getUpdates(vendorNumber) {
    return httpClient.get(`/vendor_updates/${vendorNumber}/`)
  }

  getVendorProducts(vendorNumber) {
    return httpClient.get(`/vendor_products/${vendorNumber}/`)
  }

  getProductPrices(productId) {
    return httpClient.get(`/product_prices/${productId}/`)
  }

  loadVendorUpdates(vendorNumber) {
    const queryParams = {vendor_account_number: vendorNumber}
    return httpClient.get('/action/load_vendor_updates/', {params: queryParams})
  }

  loadUpdates(vendorNumber, updateId) {
    const queryParams = {}
    if (vendorNumber) {
      queryParams.vendor_account_number = vendorNumber
    }
    if (updateId) {
      queryParams.update_id = updateId
    }
    return httpClient.get('/action/load_update/', {params: queryParams})
  }

  processUpdate(updateId) {
    const queryParams = {update_id: updateId}
    return httpClient.get('/action/process_update/', {params: queryParams})
  }

  loadPricelist(vendorNumber, queryParams, asBlob = false) {
    if (asBlob) {
      return httpClient.get(`action/vendor/${vendorNumber}/pricelist/`,
        {params: queryParams, responseType: 'blob'})
    }
    return httpClient.get(`action/vendor/${vendorNumber}/pricelist/`,
      {params: queryParams })
  }

  searchProducts(query) {
    const queryParams = {q: query}
    return httpClient.get('search/products/', {params: queryParams})
  }

  getDashboardInfos() {
    return httpClient.get('infos/dashboard')
  }

  deleteLog(updateId) {
    return httpClient.get('action/delete_log/', {params: {update_id: updateId}})
  }
}

export default new HogastWebDataService()
