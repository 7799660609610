<template>
    <div class="q-pa-md">
      <q-table
        title="Kundenliste"
        dense
        :rows="customers"
        @row-click="rowQuasarClick"
        row-key="slug_name"
      />
    </div>
</template>

<script>
import HogastWebDataService from '../services/HogastWebDataService'
import router from '@/router'
import {ref} from 'vue'

export default {
  name: 'customerList',
  setup: () => {
    const customers = ref([])

    const retrieveCustomers = function() {
      HogastWebDataService.getCustomers()
        .then(response => {
          this.customers = response.data
        })
        .catch(e => {
          console.error(e)
        })
    }
    const rowQuasarClick = function(evt, row) {
      console.log({name: 'vendor-list', params: {customer_slug: row.slug_name}})
      router.push({name: 'vendor-list', params: {customer_slug: row.slug_name}})
    }

    return {
      customers,
      retrieveCustomers,
      rowQuasarClick
    }
  },
  mounted() {
    this.retrieveCustomers()
  }
}
</script>
