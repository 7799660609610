<template>
  <div class="flex justify-center q-mt-lg">
    <q-markup-table dense style="width: 550px">
      <thead>
      <tr>
        <th>Beschreibung</th>
        <th>Wert</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>Anzahl Kunden</td>
        <td>{{ dashboardInfo.count_customers }}</td>
      </tr>
      <tr>
        <td>Anzahl Lieferanten</td>
        <td>{{ dashboardInfo.count_vendors }}</td>
      </tr>
      <tr>
        <td>Anzahl Produkte</td>
        <td>{{ dashboardInfo.count_products }}</td>
      </tr>
      <tr>
        <td>Anzahl Preise</td>
        <td>{{ dashboardInfo.count_prices }}</td>
      </tr>
      <tr>
        <td>Letzte Hogast-Transmission</td>
        <td>{{ formatDate(dashboardInfo.last_updated) }}</td>
      </tr>
      <tr>
        <td>Zuletzt vom Hogast-Webservice geladen</td>
        <td>{{ formatDate(dashboardInfo.last_loaded) }}</td>
      </tr>
      <tr>
        <td>Zuletzt verarbeitet zu Artikel/Preise</td>
        <td>{{ formatDate(dashboardInfo.last_processed) }}</td>
      </tr>
      </tbody>
    </q-markup-table>
  </div>

  <q-separator class="q-my-lg"></q-separator>

  <VueApexCharts
    class="q-py-lg"
    width="55%"
    :options="chartOptions"
    :series="chartSeries"
  ></VueApexCharts>

  <q-separator class="q-my-lg"></q-separator>

  <q-table
    title="Lieferanteninformationen"
    :rows="dashboardInfo.vendors"
    :columns="columns"
    :pagination="{rowsPerPage: 0}"
    dense
  >
  </q-table>

</template>

<script setup>
import HogastWebDataService from '@/services/HogastWebDataService'
import {ref, onMounted} from 'vue'
import {date, useQuasar} from 'quasar'
import VueApexCharts from 'vue3-apexcharts'

const $q = useQuasar()
const dashboardInfo = ref({})
const today = new Date()

function formatDate(dateStr) {
  const diff = date.getDateDiff(today, dateStr, 'hours')
  return date.formatDate(dateStr, 'DD.MM.YYYY HH:mm:ss') + ` (vor ${diff} Stunden)`
}

const chartSeries = []
const chartOptions = ref({
  chart: {
    type: 'donut'
  },
  plotOptions: {
    pie: {
      startAngle: -90,
      endAngle: 270,
      donut: {
        labels: {
          show: true,
          name: {
            show: true
          },
          value: {
            show: true
          },
          total: {
            show: true
          }
        }
      }
    }
  },
  dataLabels: {
    enabled: true
  },
  fill: {
    type: 'gradient'
  },
  labels: []
})

const columns = [
  {name: 'name', field: 'name', label: 'Lieferant', align: 'left', sortable: true},
  {name: 'count_updates', field: 'count_updates', label: '# Updates', sortable: true},
  {name: 'count_products', field: 'count_products', label: '# Produkte', sortable: true},
  {name: 'count_prices', field: 'count_prices', label: '# Preise', sortable: true},
  {
    name: 'last_updated',
    field: 'last_updated',
    label: 'Letztes Update',
    sortable: true,
    format: (val, row) => formatDate(val)
  },
  {
    name: 'last_loaded',
    field: 'last_loaded',
    label: 'Zuletzt geladen',
    sortable: true,
    format: (val, row) => formatDate(val)
  },
  {
    name: 'last_processed',
    field: 'last_processed',
    label: 'Zuletzt verarbeitet',
    sortable: true,
    format: (val, row) => formatDate(val)
  }
]

function getDashboardInfos() {
  $q.loading.show({message: 'Bitte warten, Daten werden ermittelt'})
  HogastWebDataService.getDashboardInfos()
    .then(response => {
      console.log('geladen')
      dashboardInfo.value = response.data
      dashboardInfo.value.vendors.forEach(vendor => {
        chartSeries.push(vendor.count_products)
        chartOptions.value.labels.push(vendor.name)
      })
    })
    .finally(e => $q.loading.hide())
}

onMounted(() => {
  getDashboardInfos()
})

</script>

<style scoped>

</style>
